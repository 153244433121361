
import EmailTemplateService from "@/components/emails/email-templates/EmailTemplateService"
import OrganizationService from "@/services/OrganizationService"
import Dropdown from "primevue/dropdown"
import SelectButton from "primevue/selectbutton"
import { reactive, ref } from "vue"
import { RouterView } from "vue-router"

export default {
  name: "EmailTemplateSettings",
  props: {
    language: {
      type: String,
      default: null,
    },
    emailType: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
    },
    newlyAddedTemplateId: {
      type: String,
      default: null,
    },
  },
  setup(props: any) {
    const availableLocales = ref(["NL", "DE", "EN", "FR"])
    const templateLocale = ref(props.language ? props.language : "EN")
    const emailTypes = ref([
      { label: "Studies", value: "STUDIES" },
      { label: "Reports", value: "REPORTS" },
      { label: "Studies And Reports", value: "STUDIES_AND_REPORTS" },
    ])
    const emailType = ref(props.emailType ? props.emailType : emailTypes.value[0])
    const usersOfOrg = ref([])
    const endpoints = reactive({
      post: {
        url: "mailtemplates/support",
      },
      get: {
        url: "mailtemplates",
      },
      delete: {
        url: "mailtemplates",
      },
      get_language_type: {
        url: "mailtemplates/support",
      },
      put: {
        url: "mailtemplates/support",
      },
    })
    const service = ref(new EmailTemplateService())
    const organizationService = ref(OrganizationService.getInstance())
    const text = reactive({
      emptyTemplate: "Template without alias",
      newTemplate: "New Template",
      editTemplate: "Edit Email Template",
      alias: "Alias",
      title: "Title",
      body: "Body",
      save: "Save",
      delete: "Delete",
      deleteHeader: "Delete Email Template",
      deleteBody: "Are you sure you want to delete this template?",
      yes: "Yes",
      no: "No",
      newEmailTemplateTitle: "New Email Template",
      onlyVisibleForMe: "Only visible for me",
      forSpecificUser: "For specific user of organization",
      selectUser: "Select a user",
      cancel: "cancel",
    })

    if (props.organization) {
      organizationService.value.getUsersForOrganization(props.organization.id).then((response) => {
        usersOfOrg.value = response.rows.map((user) => user.user)
      })
    }

    return {
      templateLocale,
      availableLocales,
      emailTypes,
      emailType,
      endpoints,
      service,
      usersOfOrg,
      text,
    }
  },
}
