import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36ae42d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "erp",
  class: "sub-tab-body pt-3"
}
const _hoisted_2 = { class: "flex mb-4" }
const _hoisted_3 = { class: "email-templates-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SelectButton, {
        modelValue: $setup.templateLocale,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.templateLocale) = $event)),
        options: $setup.availableLocales,
        unselectable: false,
        class: "language-selector"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_Dropdown, {
        modelValue: $setup.emailType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.emailType) = $event)),
        options: $setup.emailTypes,
        placeholder: "Select a type",
        class: "ml-2",
        optionLabel: "label"
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($setup.templateLocale && $setup.emailType)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 0,
            language: $setup.templateLocale,
            emailType: $setup.emailType,
            endpoints: $setup.endpoints,
            organizationId: $props.organization.id,
            service: $setup.service,
            users: $setup.usersOfOrg,
            newlyAddedTemplateId: $props.newlyAddedTemplateId,
            text: $setup.text
          }, null, 8, ["language", "emailType", "endpoints", "organizationId", "service", "users", "newlyAddedTemplateId", "text"]))
        : _createCommentVNode("", true)
    ])
  ]))
}