import axios from "axios"
import Service from "@/services/Service"

export default class EmailTemplateService extends Service {
  private baseURL = process.env.VUE_APP_VSOL_API_URL

  getMailTemplates(url: string, language: string, studyIds?: string[], reportIds?: string[]) {
    url += "?language=" + language

    if (studyIds && studyIds.length) {
      for (let id of studyIds) {
        url += "&studyId=" + id
      }
    }

    if (reportIds && reportIds.length) {
      for (let id of reportIds) {
        url += "&reportId=" + id
      }
    }

    return axios.get(url, { baseURL: this.baseURL + "v0" }).then((response) => response)
  }

  postMailTemplate(url: string, organization: any, computer: any, user: any, body: any) {
    if (!url.includes("support")) {
      url += "?organization=" + !organization + "&computer=" + !!computer + "&user=" + !!user
    } else {
      if (organization) {
        url += "?organizationId=" + organization
      }
      if (computer) {
        url.includes("?") ? (url += "&") : (url += "?")
        url += "computerId=" + computer
      }
      if (user) {
        url.includes("?") ? (url += "&") : (url += "?")
        url += "userId=" + user
      }
    }

    return axios.post(url, body, { baseURL: this.baseURL + "v0" }).then((response) => response)
  }

  deleteMailTemplates(url: string, ids: string[]) {
    url += "?ids=" + ids[0]
    if (ids.length > 1) {
      for (let i = 1; i < ids.length; i++) {
        url += "&ids=" + ids[i]
      }
    }

    return axios.delete(url, { baseURL: this.baseURL + "v0" }).then((response) => response)
  }

  getMailTemplatesByLanguageAndType(
    url: string,
    language: string,
    type: string,
    organization?: string,
    user?: string,
    computer?: string
  ) {
    url += "?language=" + language + "&type=" + type

    if (organization) {
      url += "&organizationId=" + organization
    }
    if (user) {
      url += "&userId=" + user
    }
    if (computer) {
      url += "&computerId=" + computer
    }
    return axios.get(url, { baseURL: this.baseURL + "v0" }).then((response) => response)
  }

  getMailTemplateById(url: string, id: string) {
    url += "/" + id
    return axios.get(url, { baseURL: this.baseURL + "v0" }).then((response) => response)
  }

  putMailTemplate(url: string, id: string, body: any) {
    url += "/" + id
    return axios.put(url, body, { baseURL: this.baseURL + "v0" })
  }

  putMailTemplates(url: string, ids: string[], body: any) {
    url += "?ids=" + ids[0]
    if (ids.length > 1) {
      for (let i = 1; i < ids.length; i++) {
        url += "&ids=" + ids[i]
      }
    }

    return axios.put(url, body, { baseURL: this.baseURL + "v0" })
  }

  deleteMailTemplate(url: string, id: string) {
    url += "/" + id
    return axios.delete(url, { baseURL: this.baseURL + "v0" }).then((response) => response)
  }
}
